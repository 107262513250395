<template>
  <div
    class="components_task_fun_bar_page d-flex justify-space-between bbox align-center"
  >
    <div
      v-if="!system && !task_config.hide_fun"
      class="origin_group"
    >
      来源: {{ origin }}
    </div>
    <div
      v-else
    ></div>
    <div
      class="fun_group d-flex align-center"
    >
      <template
        v-if="!task_config.hide_fun"
      >
        <!-- 财务标记开关 -->
        <Finance
          v-if="0"
          class="mr-5"
        ></Finance>
        <color
          v-if="color_status"
          :task="task"
          :vip="vip"
          class="h-24 px-10 border-left"
        ></color>
        <privacy
          v-if="privact_status"
          :task="task"
          :vip="vip"
          class="h-24 px-10 border-left border-right"
        ></privacy>
        <button-group
          :system="system"
          :view="view"
          :editable="editable"
          :recycle="recycle"
          @handleCall="callTask"
          @handleUse="useTask"
          @handleSend="handleSend"
        ></button-group>
        <el-popover
          placement="bottom-end"
          trigger="click"
          :visible-arrow="false"
          popper-class="components_task_fun_bar_page_popover"
          v-model="switch_popover"
          :disabled="!menu_status"
          :class="{disabled: !editable}"
        >
          <div
            class="list un_sel"
          >
            <div
              v-if="share_status"
              class="item bbox cp"
              @click="doShare"
            >
              分享
            </div>
            <div
              class="item bbox cp"
              v-if="callable"
              @click="doCall"
            >
              调用
            </div>
            <div
              class="item bbox cp"
              v-text="`${task.task.collectionflag? '取消':''}收藏`"
              @click="touchCollect"
            ></div>
            <!-- 【折叠内容开关】 -->
            <div
              v-if="0"
              class="item bbox cp"
              @click="touchFold"
            >
              {{ taskFold ? '展开' : '折叠' }}控件
            </div>
            <!-- 【清空标题开关】 -->
            <div
              v-if="configCall"
              class="item bbox cp"
              @click="touchClearWidgetTitle"
            >
              清空标题
            </div>
            <!-- 【清空内容开关】 -->
            <div
              v-if="configCall"
              class="item bbox cp"
              @click="touchClearWidgetContent"
            >
              清空内容
            </div>
            <!-- 【打印功能开关】 -->
            <div
              v-if="editable"
              class="item bbox cp"
              @click="touchPrint"
            >
              打印
            </div>
            <!-- 【迁移任务功能开关】 -->
            <div
              v-if="permissionOfMigrateTask"
              class="item bbox cp"
              @click="touchMigrateTask"
            >
              任务迁移
            </div>
            <div
              v-if="delete_task_status"
              class="item bbox cp"
              @click="doDelete"
            >
              删除
            </div>
            <div
              v-if="recycle"
              class="item bbox cp"
              @click="doRecover"
            >
              恢复
            </div>
            <div
              v-if="recycle"
              class="item bbox cp"
              @click="completeDelete"
            >
              彻底删除
            </div>
          </div>
          <template
            slot="reference"
          >
            <div
              v-if="menu_status"
              class="menu cp hover_btn"
            ></div>
          </template>
        </el-popover>
      </template>
      <!-- 任务最小化按钮 -->
      <div
        v-if="1"
        class="minimize_group d-flex align-center justify-center un_sel hover_btn ml-5"
        @click.stop="doMinimize()"
      >
        <div class="minimize"
        ></div>
      </div>
      <div class="close cp hover_btn"
        @click="closeTask();"
      ></div>
    </div>
  </div>
</template>
<script>
import ButtonGroup from './FunBar/ButtonGroup.vue'
import Finance from '@/components/Task/FunBar/Finance'
import Privacy from './FunBar/Privacy.vue'
import Color from './FunBar/Color.vue'
import global from '@/api/global.js'
import store from '@/store'
import { LargeTask } from '@/classes'
import tools from '@/api/tools'
export default {
  components:{
    ButtonGroup,
    Privacy,
    Color,
    Finance
  },
  props: {
    task: {
      type: Object,
      default: function(){
        return {}
      },
    },
    editable: {
      type: Boolean,
      default: true,
    },
    view: {
      type: Boolean,
      default: false,
    },
    vip: {
      type: Boolean,
      default: false,
    },
    system: {
      type: Boolean,
      default: false,
    },
    recycle: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      switch_popover: false,
      loading: false,
    }
  },
  computed: {
    configCall () {
      const { state } = store
      const { task_config } = state
      const { call } = task_config
      return call
    },
    taskFold() {
      try {
        const { task } = this
        return task.task.taskFold
      } catch (e) {
        return false
      }
    },
    /**
     * 迁移任务权限
     * 条件： 工作组 && (工作组创建者 || 任务创建者)
     * @returns {boolean|default.computed.isCreateGroupUser|default.computed.isCreateTaskUser}
     */
    permissionOfMigrateTask () {
      const { isGroup, isCreateGroupUser, isCreateTaskUser } = this
      if (!isGroup) {
        return false
      }
      return isCreateGroupUser || isCreateTaskUser
    },
    isGroup () {
      try {
        const { task } = this
        const { chatType } = task.task
        return Number(chatType) === 1
      } catch (e) {
        return false
      }
    },
    isCreateGroupUser () {
      try {
        const user_id = tools.getUserId()
        const { state } = store
        const { chat_detail } = state
        const { chat, chatType, chatId } = chat_detail
        const { createUserId } = chat
        console.log(chat_detail, 'chat_detail')
        const routerParamId = this.$route.params.id
        if (!routerParamId) {
          return false
        }
        const currentChatId = tools.atob(routerParamId)
        if (Number(currentChatId) !== Number(chatId)) {
          return false
        }
        if (Number(chatType) === 0) {
          return false
        }
        return Number(user_id) === Number(createUserId)
      } catch (e) {
        console.log(e)
        return false
      }
    },
    /**
     * 任务创建者
     * @returns {boolean}
     */
    isCreateTaskUser () {
      try {
        const { task } = this
        const { userId } = task.task
        const user_id = tools.getUserId()
        return Number(user_id) === Number(userId)
      } catch (e) {
        return false
      }
    },
    callable(){
      const { editable,task } = this
      const { draft } = task.task
      if (draft) {
        return false
      }
      return editable && task.task && task.task.id
    },
    origin(){
      const {task, view} = this;
      const {task_config} = this.$store.state;
      const task_config_from = task_config.from;
      if(view){
        return '';
      }
      if(task_config_from){
        return task_config_from;
      }
      if(task.task){
        const {from} = task.task;
        return from;
      }
      return '';
    },
    delete_task_status(){
      const {view, recycle} = this;
      const {task_config} = this.$store.state;
      const userid = this.$tools.getUserId();
      const {draft, task_type, creater_id, call } = task_config;
      if(recycle){
        return false;
      }
      if(view && (creater_id != userid)){
        return false;
      }
      if(call){
        return false;
      }
      // 草稿 || (创建者 ) 
      if(draft || (creater_id == userid)){
        return true;
      }
      return false;
    },
    share_status () {
      const { recycle } = this
      const { task_config } = this.$store.state
      const { editable, view, create, draft } = task_config
      if (recycle) {
        return false
      }
      if (view) {
        return true
      }
      if (create) {
        return false
      }
      if (draft) {
        return false
      }
      if (editable) {
        return true
      }
      return true
    },
    menu_status () {
      const { delete_task_status, share_status, system, recycle } = this
      if (recycle) {
        return true
      }
      if (system) {
        return false
      }
      if (delete_task_status || share_status) {
        return true
      }
      return false
    },
    color_status () {
      const { task_config } = this.$store.state
      const { task_type, draft, call } = task_config
      const { view, editable } = this
      if (call) {
        return true
      }
      if (task_type == 2 && !draft) {
        return false;
      }
      if (editable && !view) {
        return true
      }
      return false
    },
    privact_status () {
      const { task_config}  = this.$store.state
      const { task_type, draft, call } = task_config
      const { view, editable } = this
      if (call) {
        return true
      }
      if (task_type == 2 && !draft) {
        return false;
      }
      if (editable && !view) {
        return true
      }
      return false
    },
    task_config () {
      return this.$store.state.task_config
    }
  },
  methods: {
    /**
     * 清空控件标题
     */
    touchClearWidgetTitle () {
      this.closePoppver()
      // console.log(this.task)
      const { task } = this
      task.task.clearWidgetTitle = false
      this.$nextTick(function () {
        task.task.clearWidgetTitle = true
      })
      const { taskDeatails } = task
      if (!task.task.id) {
        if (taskDeatails && taskDeatails.length) {
          taskDeatails.forEach((item) => {
            item.remark = ''
            if (item.controlLinkList && item.controlLinkList) {
              item.controlLinkList.forEach((childItem) => {
                childItem.remark = ''
              })
            }
          })
        }
      }
    },
    /**
     * 清空控件内容
     */
    touchClearWidgetContent () {
      this.closePoppver()
      const { task } = this
      task.task.clearWidgetContent = false
      this.$nextTick(function() {
        task.task.clearWidgetContent = true
      })
      const { taskDeatails } = task

      if (!task.task.id) {
        if (taskDeatails && taskDeatails.length) {
          taskDeatails.forEach((item) => {
            // item.remark = ''
            if (item.controlLinkList && item.controlLinkList) {
              item.controlLinkList.forEach((childItem) => {
                // childItem.remark = ''
                this.clearLinkContent(childItem)
              })
            }
          })
        }
      }

    },
    clearLinkContent (task) {
      const { type } = task
      if (type === 1) {
        task.content = ''
      }
      if (type === 2) {
        task.content = ''
        task.unit = ''
      }
      if (type === 3) {
        task.content = ''
        task.unit = ''
      }
      if (type === 4) {
        task.content = ''
        task.unit = ''
      }
      if (type === 6) {
        task.fileList = []
        task.fileJson = ''
      }
      if (type === 7) {
        task.content = ''
      }
      if (type === 9) {
        task.fileList = []
        task.fileJson = ''
      }
      if (type === 10) {
        task.userList = []
      }
      if (type === 11) {
        task.content = ''
        task.countdown = '' // 倒计时
        task.screenList = []
      }
    },
    /**
     * 折叠/展开
     */
    touchFold () {
      this.closePoppver()
      const { task } = this
      const { taskFold } = task.task
      task.task.taskFold = !taskFold
      console.log(task.task.taskFold)
    },
    /**
     * 任务迁移
     */
    touchMigrateTask () {
      // console.log(this.task)
      this.closePoppver()
      const { task } = this
      this.closeTask({
        title: '可能有未保存的内容',
        desc: '是否关闭任务进行迁移？',
        onSure: () => {
          this.$openMigrateTaskSelectTargetGroup({
            taskList: [ task.task ],
            cancelCallback: () => {
              this.$api.moduleTask.openTask({
                taskId: task.task.id,
                config: this.task_config
              })
            }
          })
        },
        callback: () => {
          this.$openMigrateTaskSelectTargetGroup({
            taskList: [ task.task ],
            cancelCallback: () => {
              this.$api.moduleTask.openTask({
                taskId: task.task.id,
                config: this.task_config
              })
            }
          })
        }
      })
    },
    touchPrint() {
      console.log(this.task)
      const teach_files = store.state.teach_files
      const taskUsers = store.state.task_config.users
      const friendsList = store.state.friends_list
      console.log(teach_files)
      const largeTask = new LargeTask({
        otask: this.task,
        teachData: teach_files
      })
      console.log(largeTask, taskUsers)
      tools.setCookie('printData', {
        largeTask,
        taskUsers,
        friendsList
      })
      window.open(`/PrintPage?taskId=${largeTask.otask.task.id}`, '_blank')
      this.closePoppver()
    },
    closeTask (params) {
      this.$api.moduleTask.closeTask(params)
    },
    closePoppver(){
      this.switch_popover = false;
    },
    doDelete(){
      const {task_config} = this.$store.state;
      const {draft, chat_id, chat_type, task_id, task_name} = task_config;
      this.closePoppver();
      this.$openConfirm({
        title:`是否删除${draft===1?'草稿':'任务'}?`,
        // desc:'该操作无法撤销',
        sure:'是',
        cancel:'否',
        onSure:async ()=>{

          if(draft){
            // 删除草稿
            const res = await this.$api.editTask.deleteDraft({id: task_id});
            if(res){
              this.$api.editTask.deleteDraftList({taskId: task_id});
            }
          }else{
            // 删除任务
            let res = await this.$api.deleteTask({
              taskId: task_id, 
              chatId:chat_id, 
              chatType: chat_type,
            });
            if(res){
              // 追加删除简述
              let task_log_unit = {
                chatId: chat_id,
                deleteFlag: 1,
                details: [
                  {
                    content: '',
                    controlType: 101,
                    createTime: new Date().getTime(),
                    title: task_name,
                    type: 3,
                  }
                ],
                createTime: new Date().getTime(),
                fromUserId: this.$tools.getUserId(),
                linkme: true,
                taskName: null,
                taskType: null,
                thumbnail: this.$tools.getCookie('user').thumbnail,
                type: 1,
                userName: this.$tools.getCookie('user').userName,
              }
              // 删除之前的任务简述(前端伪删除)
              const {message_list} = this.$store.state;
              if(message_list && message_list.length){

                message_list.forEach(item=>{
                  if(item.taskId == task_id){
                    item.pseudo_delete = true;
                  }
                })
                this.$api.moduleTask.common.pushMessageList(task_log_unit);
              }
            }
          }
          this.$store.commit('set_switch_task_box', false);  //关闭
          // this.switch_fun_menu = false;
        },
        onCancel: ()=>{
          // this.switch_fun_menu = false;
         
        }
      })
     
    },
     /**
     * @description: 分享任务单 
     * @param {*}
     * @return {*}
     */    
    doShare(){
      const {commit, state} = this.$store;
      const {task} = state;
      commit('set_switch_multiple_contacts', true);
      commit('set_share_data', task.task);
      commit('set_multiple_contacts_callback', this.$api.share.task);
      this.closePoppver()
    },
    doCall(){
      this.callTask(this.task.task.id)
    },
    callTask(taskId){
      // this.$emit('handleCall', taskId);
      this.$emit(
        'handleCall',
        {
          taskId,
          config: {
            select_target: true,
            jump: true,
            use: true
          }
        }
      )
    },
    useTask(taskId){
      this.$emit('handleUse', taskId);
    },
    handleSend(){
      this.$emit('handleSend');
    },
    doRecover(){
      this.closePoppver();
      this.$emit('handleRecover');
    },
    completeDelete(){
      this.closePoppver();
      this.$emit('handleCompleteDelete');
    },

    async touchCollect(){
      // TODO 选择文件夹
      const {task} = this
      if(task.task.collectionflag){
        if(!this.loading){
          this.loading = true

       
          const res = await this.$global.doPost({
            url: '/projectCustom/deleteFolderTask',
            data: {
              deleteIds: task.task.id,
            }
          })
          this.loading = false
          if(res.message === 'success'){
            this.task.task.collectionflag = 0
            this.$notice({desc:"移除成功", type: 'success'})
          }
        }
      }else{
        this.$collectSelectRoute({
          onSure: async (params)=>{
            const {p_folder} = params
            const res = await global.doPost({
              url: '/projectCustom/addFolderTask',
              data: {
                folderId: p_folder.id,
                addIds: task.task.id,
              }
            })
            if(res.message === 'success'){
              task.task.collectionflag = 1
              this.$notice({desc:"收藏成功", type: 'success'})
              this.handleClose()
            }
          }
        })
      }
      this.switch_popover = false

      // const {task} = this
      // const {id, collectionflag} = task.task
      // let url = '/projectCustom/addFolderTask'
      // if(collectionflag){
      //   url = '/projectCustom/deleteFolderTask'
      // }
      // if(id){
        
      // }
    },
    
    /**
     * 最小化
     */
    doMinimize(){
      const { state } = store
      const { ctask_list, task, task_config, task_original, annc_switch, annc_data } = state
      if (ctask_list.length >= 5) {
        this.$notice({
          desc: '最小化数量超出上限，请先关闭其他任务'
        })
        return
      }
      const large_task = new LargeTask({
        otask: task,
        otask_config: task_config,
        otask_origin: task_original,
      })
      ctask_list.push(large_task)
      // this.closeTask()
      store.commit('set_switch_task_box', false)
      try {
        console.log({
          annc_switch,
          annc_data
        })
        if (annc_switch) {
          store.commit('set_task', annc_data.task)
          store.commit('set_task_original', annc_data.task_original)
          store.commit('set_task_config', annc_data.task_config)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.components_task_fun_bar_page{
  border-bottom: 1px solid #F4F4F4;
  height: 50px;
  padding: 0 20px;
  padding-right: 10px;
  width: 100%;
  .origin_group{
    font-size: 16px;
    color: #999999;
  }
  .menu{
    @include background(20px, 5px);
    width: 30px;
    height: 30px;
    margin-left: 5px;
    background-image: url(~@/assets/images/task/menu.png);
  }
  .minimize_group{
    width: 30px;
    height: 30px;
    .minimize{
      width: 14px;
      height: 2px;
      border-radius: 2px;
      background-color: #868C91;
    }
  }
  .close{
    @include background(12px, 12px);
    width: 30px;
    height: 30px;
    margin-left: 5px;
    background-image: url(~@/assets/images/task/close.png);
  }
  .hover_btn{
    @include transition;
    background-color: transparent;
    border-radius: 4px;
    &:hover{
      background-color: #eee;
    }
  }
  .px-10{
    padding-left: 10px;
    padding-right: 10px;
  }
  .h-24{
    height: 24px;
  }
  .border-left{
    border-left: 1px solid #f4f4f4;
  }
  .border-right{
    border-right: 1px solid #f4f4f4;
  }
}
</style>
<style lang="scss">
.components_task_fun_bar_page_popover{
  padding: 5px 0;
  margin-top: 0px!important;
  width: 100px;
  min-width: 100px;
  .list{
    .item{
      @include transition;
      font-size: 14px;
      color: #333333;
      line-height: 34px;
      padding: 0 10px;
      background-color: #fff;
      &.active{
        font-weight: bold;
      }
      &:hover{
        background-color: #ddd;
      }
    }
  }
}
</style>