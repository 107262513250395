var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_fun_bar_page d-flex justify-space-between bbox align-center",
    },
    [
      !_vm.system && !_vm.task_config.hide_fun
        ? _c("div", { staticClass: "origin_group" }, [
            _vm._v("\n    来源: " + _vm._s(_vm.origin) + "\n  "),
          ])
        : _c("div"),
      _c(
        "div",
        { staticClass: "fun_group d-flex align-center" },
        [
          !_vm.task_config.hide_fun
            ? [
                0 ? _c("Finance", { staticClass: "mr-5" }) : _vm._e(),
                _vm.color_status
                  ? _c("color", {
                      staticClass: "h-24 px-10 border-left",
                      attrs: { task: _vm.task, vip: _vm.vip },
                    })
                  : _vm._e(),
                _vm.privact_status
                  ? _c("privacy", {
                      staticClass: "h-24 px-10 border-left border-right",
                      attrs: { task: _vm.task, vip: _vm.vip },
                    })
                  : _vm._e(),
                _c("button-group", {
                  attrs: {
                    system: _vm.system,
                    view: _vm.view,
                    editable: _vm.editable,
                    recycle: _vm.recycle,
                  },
                  on: {
                    handleCall: _vm.callTask,
                    handleUse: _vm.useTask,
                    handleSend: _vm.handleSend,
                  },
                }),
                _c(
                  "el-popover",
                  {
                    class: { disabled: !_vm.editable },
                    attrs: {
                      placement: "bottom-end",
                      trigger: "click",
                      "visible-arrow": false,
                      "popper-class": "components_task_fun_bar_page_popover",
                      disabled: !_vm.menu_status,
                    },
                    model: {
                      value: _vm.switch_popover,
                      callback: function ($$v) {
                        _vm.switch_popover = $$v
                      },
                      expression: "switch_popover",
                    },
                  },
                  [
                    _c("div", { staticClass: "list un_sel" }, [
                      _vm.share_status
                        ? _c(
                            "div",
                            {
                              staticClass: "item bbox cp",
                              on: { click: _vm.doShare },
                            },
                            [_vm._v("\n            分享\n          ")]
                          )
                        : _vm._e(),
                      _vm.callable
                        ? _c(
                            "div",
                            {
                              staticClass: "item bbox cp",
                              on: { click: _vm.doCall },
                            },
                            [_vm._v("\n            调用\n          ")]
                          )
                        : _vm._e(),
                      _c("div", {
                        staticClass: "item bbox cp",
                        domProps: {
                          textContent: _vm._s(
                            `${_vm.task.task.collectionflag ? "取消" : ""}收藏`
                          ),
                        },
                        on: { click: _vm.touchCollect },
                      }),
                      0
                        ? _c(
                            "div",
                            {
                              staticClass: "item bbox cp",
                              on: { click: _vm.touchFold },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.taskFold ? "展开" : "折叠") +
                                  "控件\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.configCall
                        ? _c(
                            "div",
                            {
                              staticClass: "item bbox cp",
                              on: { click: _vm.touchClearWidgetTitle },
                            },
                            [_vm._v("\n            清空标题\n          ")]
                          )
                        : _vm._e(),
                      _vm.configCall
                        ? _c(
                            "div",
                            {
                              staticClass: "item bbox cp",
                              on: { click: _vm.touchClearWidgetContent },
                            },
                            [_vm._v("\n            清空内容\n          ")]
                          )
                        : _vm._e(),
                      _vm.editable
                        ? _c(
                            "div",
                            {
                              staticClass: "item bbox cp",
                              on: { click: _vm.touchPrint },
                            },
                            [_vm._v("\n            打印\n          ")]
                          )
                        : _vm._e(),
                      _vm.permissionOfMigrateTask
                        ? _c(
                            "div",
                            {
                              staticClass: "item bbox cp",
                              on: { click: _vm.touchMigrateTask },
                            },
                            [_vm._v("\n            任务迁移\n          ")]
                          )
                        : _vm._e(),
                      _vm.delete_task_status
                        ? _c(
                            "div",
                            {
                              staticClass: "item bbox cp",
                              on: { click: _vm.doDelete },
                            },
                            [_vm._v("\n            删除\n          ")]
                          )
                        : _vm._e(),
                      _vm.recycle
                        ? _c(
                            "div",
                            {
                              staticClass: "item bbox cp",
                              on: { click: _vm.doRecover },
                            },
                            [_vm._v("\n            恢复\n          ")]
                          )
                        : _vm._e(),
                      _vm.recycle
                        ? _c(
                            "div",
                            {
                              staticClass: "item bbox cp",
                              on: { click: _vm.completeDelete },
                            },
                            [_vm._v("\n            彻底删除\n          ")]
                          )
                        : _vm._e(),
                    ]),
                    _c("template", { slot: "reference" }, [
                      _vm.menu_status
                        ? _c("div", { staticClass: "menu cp hover_btn" })
                        : _vm._e(),
                    ]),
                  ],
                  2
                ),
              ]
            : _vm._e(),
          1
            ? _c(
                "div",
                {
                  staticClass:
                    "minimize_group d-flex align-center justify-center un_sel hover_btn ml-5",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.doMinimize()
                    },
                  },
                },
                [_c("div", { staticClass: "minimize" })]
              )
            : _vm._e(),
          _c("div", {
            staticClass: "close cp hover_btn",
            on: {
              click: function ($event) {
                return _vm.closeTask()
              },
            },
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }