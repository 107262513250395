var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_fun_bar_button_group_page d-flex align-center",
    },
    [
      _vm.draft_status
        ? _c(
            "div",
            { staticClass: "button draft", on: { click: _vm.seveDraft } },
            [_vm._v("保存草稿")]
          )
        : _vm._e(),
      _vm.send_status
        ? _c(
            "div",
            {
              staticClass: "button btn_dark send",
              on: { click: _vm.sendTask },
            },
            [_vm._v("\n    发布任务\n  ")]
          )
        : _vm._e(),
      _vm.use_status
        ? _c(
            "div",
            { staticClass: "button btn_dark send", on: { click: _vm.doUse } },
            [_vm._v("我要使用")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }